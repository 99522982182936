import http from "@/app/config/http"

class Auth {
    // user login
    async login (data) {
        return await http.post("/admin/auth/login", data)
    }

    // user logout
    async logout () {
        return await http.post("/admin/auth/logout")
    }
}

export default new Auth()
