// =============================
// APP ROUTING
// =============================

import { createRouter, createWebHistory } from "vue-router"

// guards
import AuthGuard from "@/app/guards/auth.guard"


const routes = [
    {
        path: '/:pathMatch(.*)*',
        component: () => import("@/views/NotFound.vue"),
    },
    {
        path: "/",
        name: "Index",
        component: () => import("@/Index.vue"),
        beforeEnter: (to, from, next) => AuthGuard.unauthenticated(next)
    },

    // DASHBOARD
    {
        path: "/dashboard",
        component: () => import("@/views/dashboard/Index.vue"),
        beforeEnter: (to, from, next) => AuthGuard.authenticated(next),
        children: [
            {
                path: '',
                name: 'Statistics',
                component: () => import('@/views/dashboard/Statistics.vue'),
            },
        ],
    },

    // ITINERARIES
    {
        path: "/itineraries",
        component: () => import("@/views/itineraries/Index.vue"),
        beforeEnter: (to, from, next) => AuthGuard.authenticated(next),
        children: [
            {
                path: '',
                name: 'Itineraries',
                component: () => import('@/views/itineraries/Itineraries.vue'),
            },
            {
                path: ':id/:leaving_from/:going_to',
                name: 'Itinerary',
                component: () => import('@/views/itineraries/Itinerary.vue'),
            },
        ],
    },

    // USERS
    {
        path: "/users",
        component: () => import("@/views/users/Index.vue"),
        beforeEnter: (to, from, next) => AuthGuard.authenticated(next),
        children: [
            {
                path: '',
                name: 'Users',
                component: () => import('@/views/users/Users.vue'),
            },
            {
                path: ':phone/:id',
                name: 'User',
                component: () => import('@/views/users/User.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior () {
        // always scroll to top
        return { top: 0 }
    }
});

export default router;
