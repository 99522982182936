// HTTP JS
import axios from "axios"
import axiosRetry from "axios-retry"
import Notify from 'quasar/src/plugins/Notify.js';
import { AuthStore } from '@/store/auth.store'

const isDev = process.env.NODE_ENV === "development"

// create a new axios instance
const http = axios.create({
    baseURL: isDev ? "http://192.168.1.81:8081/api" : "https://api.onzebarre.com/api",
})

// before a request is made start the nprogress
http.interceptors.request.use((config) => {
    // Do something before request is sent
    config.headers = {
        Authorization: "Bearer " + AuthStore().getToken,
    }
    return config
}, (error) => {
    // Do something with request error
    return Promise.reject(error)
})

// before a response add a response interceptor
http.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
}, (error) => {
    Notify.create({ message: error.response.data.message })

    if (error.response.status === 401) {
        localStorage.clear()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
})

axiosRetry(http, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay
})

export default http
