import { defineStore } from 'pinia'
import Auth from "@/app/services/auth.service"
import router from "@/router/router"
import Notify from 'quasar/src/plugins/Notify.js';

export const AuthStore = defineStore('AuthStore', {

    // state // data
    state: () => ({
        admin: null,
        token: null,
        loading: false,
    }),

    // getters // computed
    getters: {
        getToken: (state) => state.token,
        getAdmin: (state) => state.admin,
    },

    // actions // methods
    actions: {
        // login
        async login (data) {
            this.loading = true
            await Auth.login(data)
                .then((response) => {
                    this.admin = response.data.admin
                    this.token = response.data.token
                    this.loading = false
                    router.push({ name: "Statistics" })
                })
                .catch(() => {
                    this.loading = false
                })
        },

        // logout
        async logout () {
            this.loading = true
            await Auth.logout()
                .then((response) => {
                    this.admin = null
                    this.token = null
                    Notify.create(response.data.message)
                    this.loading = false
                    localStorage.clear()
                    router.push({ name: "Index" })
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
    
    persist: true,
})