// =======================================================================
// MAIN APP ENTRY
// =======================================================================

import { createApp } from "vue"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { createPinia } from "pinia"

import App from "@/App.vue"

// pinia
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// vue extended packages
import router from "@/router/router"

// quasar
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from "@/quasar-user-options"

// luxon
import { DateTime } from "luxon"

import VueEasyLightbox from 'vue-easy-lightbox'

// filters
import filters from "@/app/filters/filters"

// initialize vue instance
const app = createApp(App)

// configuration
const isDev = process.env.NODE_ENV === "development"

// global properties
// -----------------------
// luxon datetime
app.config.globalProperties.$luxon = DateTime

// filters
app.config.globalProperties.$filters = filters

// path
app.config.globalProperties.storage_path = isDev ? "http://192.168.1.81:8081/storage" : "https://api.onzebarre.com/storage"

app.use(VueEasyLightbox)
app.use(Quasar, quasarUserOptions)
app.use(pinia) // Create the root store
app.use(router)
app.mount("#app")
