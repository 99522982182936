<template>
    <q-layout view="hHh lpR fFf">
        <router-view />
    </q-layout>
</template>

<script>

export default {
    name: "App",
    components: {},
    data () {
        return {}
    },
    computed: {
        //
    },
    methods: {
        //
    },
    created () {}
};
</script>

<style lang="scss">
    body {
        background-color: #EEEEEE;
    }
    
    // file transfer area
    .file-transfer-area {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-content: flex-start;
        height: 5%;
        border: 0.2rem dashed #919191;

        &:hover {
            border: 0.2rem dashed #b6b6b6;
        }

        .file-transfer-preview {
            width: 250px;
        }

        .file-transfer-instructions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }
</style>
