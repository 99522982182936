// =============================
// APP FILTERS
// =============================

const filters = {

    // nullable, undefined or NaN value
    // -----------------
    default (val) {
        return val ?? 'n/a';
    },

    // truncate text
    // -----------------
    truncate (str, num) {
        return str && str.length > num ? str.slice(0, num) + "..." : str;
    },

    // order statuses
    // -----------------
    status (status) {
        const statusMap = {
            "pending": "En cours",
            "finished": "Terminée",
            "cancel": "Annulée",
            "denied": "Réfusée"
        }
    
        return statusMap[status] || "";
    },

    // format bytes for human readable
    bytes (bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
    
        const k = 1024;
        const dm = decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    
        return `${formattedBytes} ${sizes[i]}`;
    }
    
}

export default filters
