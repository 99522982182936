import { AuthStore } from '@/store/auth.store'

class AuthGuard {
    // authenticated
    authenticated(next) {
        if (AuthStore().getToken == null) {
            return next({ name: "Index" }) // redirect
        }
        next()
    }

    // unauthenticated
    unauthenticated(next) {
        if (AuthStore().getToken != null) {
            return next({ name: "Statistics" }) // redirect
        }
        next()
    }
}

export default new AuthGuard()